/*
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import { SurveyPageWrap } from 'components/pages';

import OldApp from './old-app';

const App: NextPage<
    AppProps & {
        initialState: any;
    }
> = ({ ...props }) => {
    return <OldApp {...props} Component={SurveyPageWrap} />;
};

export default App;
*/
import { SurveyNewApp } from '@webapp/survey-new/src/pages/index';
export default SurveyNewApp;